import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { SanityBlocks } from "sanity-blocks-vue-component";
import VueClickAway from "vue3-click-away";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(router);
app.use(VueClickAway);
app.use(
	VueGtag,
	{
		config: {
			id: "G-7Q0838VXJ9"
		}
	},
	router
);
app.component("sanity-rich-text", SanityBlocks);
app.mount("#app");
